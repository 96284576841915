const mediaMobile = '';
const mediaTablet = '(min-width: 1024px)';
const mediaDesktop = '(min-width: 1280px)';

const headerSelector = '.header';
const menuSelector = '.header-menu';
const submenuSelector = '.header-menu-submenu';
const ribbonMenuItemSelector = '.header-ribbon-menu-item';
const userMenuClassName = 'header-user-menu';
const userMenuSelector = `.${userMenuClassName}`;
const searchSelector = '.header-search';
const showClassName = 'header-show';
const animatableClassName = 'header-animatable';
const fadeClassName = 'header-fade';
const activeClassName = 'header-active';
const coveredClassName = 'header-covered';
const menuClassName = 'header-menu';
const menuOpenClassName = 'header-menu-open';
const hasChildrenClassName = 'header-has-children';
const customSlideoutMenuClassName = 'header-slideout-menu';

let headerElement;
let openedLayer;

const dropdowns = [
	{
		buttonMatch: element => element.hasAttribute('aria-haspopup'),
		getElement: element => document.getElementById(element.getAttribute('aria-controls')),
	},
	{
		buttonMatch: element =>
			element.classList.contains('header-ribbon-menu-button') &&
			element.classList.contains(hasChildrenClassName),
		getElement: element =>
			element
				.closest(ribbonMenuItemSelector)
				.querySelector('.header-ribbon-submenu, .header-megamenu'),
	},
	{
		buttonMatch: element =>
			element.tagName === 'BUTTON' &&
			element.classList.contains('header-ribbon-submenu-button') &&
			element.classList.contains(hasChildrenClassName),
		getElement: () => false,
		// element.closest('.header-ribbon-submenu-item').querySelector('.header-ribbon-submenu'),
	},
	{
		buttonMatch: element => element.classList.contains('header-user-button'),
		getElement: element => element.closest('.header-user').querySelector(userMenuSelector),
	},
];

const getCurrentBreakpoint = () => {
	if (window.matchMedia(mediaDesktop).matches) {
		return mediaDesktop;
	}
	if (window.matchMedia(mediaTablet).matches) {
		return mediaTablet;
	}
	return mediaMobile;
};

const setArrowPosition = (dropdown, button) => {
	const buttonWidth = button.getBoundingClientRect().width;
	const arrow = dropdown.querySelector('.header-dropdown-arrow');
	if (arrow) {
		arrow.style.right = `${buttonWidth / 2}px`;
	}
};

const menuBackdrop = new Map();

const getMenuBackdrop = (closeMenuMethod, parentElement) => {
	if (menuBackdrop.has(parentElement) && menuBackdrop.get(parentElement).parentElement) {
		return menuBackdrop.get(parentElement);
	}
	const backdropElement = document.createElement('div');
	backdropElement.classList.add('header-menu-backdrop');
	backdropElement.addEventListener('transitionend', event => {
		if (event.target.classList.contains(fadeClassName)) {
			event.target.classList.remove(showClassName, fadeClassName);
		}
	});
	backdropElement.addEventListener('click', () => closeMenuMethod());
	if (document.readyState !== 'loading ') {
		parentElement.appendChild(backdropElement);
	} else {
		document.addEventListener('DOMContentLoaded', () => parentElement.appendChild(backdropElement));
	}
	menuBackdrop.set(parentElement, backdropElement);
	return backdropElement;
};

const forEachBackdrop = (callback, closeMenuMethod) => {
	[
		document.querySelector('.header-backdrop-parent') || document.body,
		document.querySelector('.header-middle-ribbon'),
	].forEach(backdropParent => {
		if (!backdropParent) {
			return;
		}
		callback(getMenuBackdrop(closeMenuMethod, backdropParent));
	});
};

let hideLayerTimeout;
export const hideLayer = (toggle = false, force = false) => {
	if (!openedLayer) {
		return;
	}
	clearTimeout(hideLayerTimeout);
	const { button, element, parent, isSlideout } = openedLayer;
	element.classList.remove(showClassName);
	if (button) {
		button.classList.remove(activeClassName);
		if (button.dataset.uetLinkUrl && button.dataset.uetLinkUrl.substr('@close') !== -1) {
			setTimeout(() => {
				button.dataset.uetLinkUrl = button.dataset.uetLinkUrl.replace('@close', '@open');
			}, 0);
		}
	}
	if (isSlideout) {
		document.body.classList.remove(menuOpenClassName);
		element
			.querySelectorAll(`.${coveredClassName}`)
			.forEach(coveredElement => coveredElement.classList.remove(coveredClassName));
		if (element.classList.contains(menuClassName)) {
			forEachBackdrop(backdrop => {
				if (force) {
					backdrop.classList.add(fadeClassName);
				} else {
					backdrop.classList.remove(showClassName);
				}
			}, hideLayer);
		}
		element
			.querySelectorAll(submenuSelector)
			.forEach(submenu => submenu.classList.remove(showClassName));
	}
	if (parent) {
		if (toggle) {
			openedLayer = parent;
		} else {
			parent.element.classList.remove(showClassName);
			parent.button.classList.remove(activeClassName);
			openedLayer = null;
		}
	} else {
		openedLayer = null;
	}
};

export const showLayer = (element, button, isSlideout) => {
	if (openedLayer && openedLayer.element === element) {
		return;
	}
	let parent;
	if (openedLayer) {
		if (openedLayer.parent) {
			if (openedLayer.parent.element.contains(element)) {
				parent = openedLayer.parent;
				hideLayer(true);
			} else {
				hideLayer();
			}
		} else if (openedLayer.element.contains(element)) {
			parent = openedLayer;
		} else {
			hideLayer();
		}
	}
	if (isSlideout) {
		document.body.classList.add(menuOpenClassName);
		if (element.classList.contains(menuClassName)) {
			forEachBackdrop(backdrop => {
				backdrop.classList.remove(fadeClassName);
				backdrop.classList.add(showClassName);
			}, hideLayer);
		}
	}
	if (button) {
		setArrowPosition(element, button);
		button.classList.add(activeClassName);
		if (button.dataset.uetLinkUrl && button.dataset.uetLinkUrl.substr('@open') !== -1) {
			setTimeout(() => {
				// eslint-disable-next-line no-param-reassign
				button.dataset.uetLinkUrl = button.dataset.uetLinkUrl.replace('@open', '@close');
			}, 0);
		}
	}
	element.classList.add(showClassName);
	const searchInput = element.querySelector('.header-ribbon-submenu-input');
	if (searchInput) {
		searchInput.focus();
	}
	openedLayer = { element, button, parent, isSlideout };

	if (element.onShowLayerCallback) {
		element.onShowLayerCallback();
	}
};

export const toggleLayer = (element, button) => {
	if (!element) {
		return;
	}
	if (element.classList.contains(showClassName)) {
		hideLayer(element === openedLayer.element);
	} else {
		showLayer(element, button);
	}
};

const toggleMenu = (element, button) =>
	element.classList.contains(showClassName) ? hideLayer() : showLayer(element, button, true);

const openSubmenu = target => {
	const element = target.nextElementSibling;
	const parent = target.closest(submenuSelector) || target.closest(menuSelector);
	parent.classList.add(coveredClassName);
	if (parent.matches(submenuSelector)) {
		element.style.top = `${parent.scrollTop}px`;
	}
	if (!element.classList.contains(showClassName)) {
		element.classList.add(showClassName);
	}
	if (element.onShowSubmenuCallback) {
		element.onShowSubmenuCallback();
	}
};

const closeSubmenu = target => {
	const currentSubmenu = target.closest(submenuSelector);
	currentSubmenu.classList.remove(showClassName);
	(
		currentSubmenu.parentElement.closest(submenuSelector) ||
		currentSubmenu.parentElement.closest(menuSelector)
	).classList.remove(coveredClassName);
};

let currentBreakpoint;

const onClick = event => {
	let element = event.target;
	let layerToggled = false;
	let keepPopover = true;

	if (element.classList.contains('header-ribbon-menu-item')) {
		const currentElement = document.querySelector('.header-ribbon-menu-underline.header-current');
		if (currentElement) {
			currentElement.classList.remove('header-current', 'header-ribbon-menu-underline');
		}
		element.classList.add('header-ribbon-menu-underline', 'header-current');
	}

	while (element && element !== event.currentTarget) {
		if (element.tagName === 'A' && !element.dataset.keepPopover) {
			keepPopover = false;
		}
		if (element.dataset.closePopover) {
			keepPopover = false;
		}
		if (element.tagName === 'A' && element.href.endsWith('#chat-live')) {
			event.preventDefault();
			event.target.dispatchEvent(new CustomEvent('chatclick', { bubbles: true }));
		}
		if (element.classList.contains('header-menu-button')) {
			toggleMenu(event.currentTarget.querySelector(menuSelector));
			layerToggled = true;
			break;
		}
		if (element.classList.contains('header-user-button')) {
			if (currentBreakpoint === mediaMobile) {
				toggleMenu(event.currentTarget.querySelector(userMenuSelector), element);
			} else {
				toggleLayer(event.currentTarget.querySelector(userMenuSelector), element);
			}
			layerToggled = true;
			break;
		}
		if (element.classList.contains('header-menu-header-button')) {
			toggleMenu(element.closest(`.${showClassName}`));
			break;
		}
		if (
			element.classList.contains('header-search-button') ||
			element.classList.contains('header-search-close-button')
		) {
			const search = event.currentTarget.querySelector(searchSelector);
			if (search.classList.contains(showClassName)) {
				search.classList.remove(showClassName);
			} else {
				search.classList.add(animatableClassName, showClassName);
			}
			break;
		}
		for (let i = 0, c = dropdowns.length; i < c; i += 1) {
			if (dropdowns[i].buttonMatch(element)) {
				const layerElement = dropdowns[i].getElement(element);
				if (
					layerElement &&
					layerElement.classList.contains(customSlideoutMenuClassName) &&
					currentBreakpoint === mediaMobile
				) {
					toggleMenu(layerElement, element);
				} else {
					toggleLayer(layerElement, element);
				}
				layerToggled = true;
				break;
			}
		}
		if (layerToggled) {
			break;
		}
		if (
			element.classList.contains('header-menu-item') &&
			element.classList.contains(hasChildrenClassName)
		) {
			openSubmenu(element);
			layerToggled = true;
			break;
		}
		if (element.classList.contains('header-menu-submenu-button')) {
			closeSubmenu(element);
			layerToggled = true;
			break;
		}
		if (
			element.classList.contains('header-megamenu-link') &&
			element.getAttribute('role') === 'tab'
		) {
			const megamenu = element.closest('.header-megamenu');
			megamenu.querySelectorAll('[role=tab]').forEach(tab => {
				tab.classList.remove(activeClassName);
				tab.setAttribute('aria-selected', false);
			});
			element.classList.add(activeClassName);
			element.setAttribute('aria-selected', true);
			megamenu.querySelectorAll('[role=tabpanel]').forEach(tabPanel => {
				tabPanel.hidden = true; // eslint-disable-line no-param-reassign
			});
			document.getElementById(element.getAttribute('aria-controls')).hidden = false;
			layerToggled = true;
			break;
		}
		if (openedLayer && openedLayer.element === element && keepPopover) {
			layerToggled = true;
			break;
		}
		element = element.parentElement;
	}
	if (!layerToggled) {
		hideLayer();
	}
};

const toggleCanScroll = element => {
	const classTop = 'can-scroll-top';
	const classBottom = 'can-scroll-bottom';
	if (element.scrollTop < element.scrollHeight - element.offsetHeight) {
		element.classList.add(classBottom);
	} else {
		element.classList.remove(classBottom);
	}

	if (element.scrollTop > 0) {
		element.classList.add(classTop);
	} else {
		element.classList.remove(classTop);
	}
};

const minicartScroll = {};
export const toggleMinicart = (element, show, timeout) => {
	const minicart = element.querySelector('.header-minicart');
	if (minicart) {
		if (show) {
			clearTimeout(hideLayerTimeout);
			if (!openedLayer || openedLayer.element !== minicart) {
				showLayer(minicart, element.querySelector('.header-cart-button'));
				const cartTableBody = element.querySelector('.header-minicart-table > tbody');
				if (cartTableBody) {
					if (!minicartScroll.element || minicartScroll.element !== cartTableBody) {
						minicartScroll.element = cartTableBody;
						minicartScroll.callback = () => toggleCanScroll(cartTableBody);
						minicartScroll.element.addEventListener('scroll', minicartScroll.callback);
					}
					minicartScroll.callback();
				}
			}
		}
		if (!show || (show && timeout)) {
			hideLayerTimeout = setTimeout(hideLayer, timeout);
		}
	}
};

const mouseEnterLeave = (enter, timeout = 2000) => event => {
	let element = event.target;
	while (element !== event.currentTarget) {
		if (element.classList.contains('header-cart') && currentBreakpoint === mediaDesktop) {
			toggleMinicart(element, enter, !enter && timeout);
			break;
		}
		element = element.parentElement;
	}
};

const onSearchTransitionEnd = event =>
	event.target.classList.contains(showClassName) ||
	event.target.classList.remove(animatableClassName);

const onFocus = mouseEnterLeave(true, 100);
const onBlur = mouseEnterLeave(false, 100);
const onMouseEnter = mouseEnterLeave(true);
const onMouseLeave = mouseEnterLeave(false);

const hoistSecondaryNav = () => {
	const secondaryNavElement = document.getElementById('secondary-nav');
	if (secondaryNavElement) {
		headerElement.appendChild(secondaryNavElement);
		headerElement.classList.add('headroom');
	}
	return !!secondaryNavElement;
};

export const init = element => {
	// eslint-disable-next-line no-param-reassign
	const previousHeaderElement = headerElement;
	headerElement = element || document.querySelector(headerSelector);
	if (!headerElement) {
		return false;
	}
	if (previousHeaderElement) {
		if (previousHeaderElement === headerElement) {
			return false;
		}
	} else {
		// attach event listeners not bound with heder instance
		document.addEventListener(
			'click',
			event => {
				if (
					openedLayer &&
					!headerElement.contains(event.target) &&
					!openedLayer.element.contains(event.target)
				) {
					hideLayer();
				}
			},
			true,
		);
		currentBreakpoint = getCurrentBreakpoint();
		window.addEventListener('resize', () => {
			const newBreakpoint = getCurrentBreakpoint();
			if (currentBreakpoint === newBreakpoint) {
				return;
			}
			if (openedLayer) {
				const { classList } = openedLayer.element;
				if (classList.contains(menuClassName)) {
					if (
						(classList.contains('header-menu-substitute') && newBreakpoint !== mediaMobile) ||
						newBreakpoint === mediaDesktop
					) {
						hideLayer(false, true);
					}
				} else if (
					classList.contains(userMenuClassName) ||
					classList.contains(customSlideoutMenuClassName)
				) {
					if (currentBreakpoint === mediaMobile) {
						setArrowPosition(openedLayer.element, openedLayer.button);
						document.body.classList.remove(menuOpenClassName);
						openedLayer.isSlideout = false;
					} else if (newBreakpoint === mediaMobile) {
						document.body.classList.add(menuOpenClassName);
						openedLayer.isSlideout = true;
					}
				} else if (newBreakpoint === mediaDesktop || currentBreakpoint === mediaDesktop) {
					setArrowPosition(openedLayer.element, openedLayer.button);
				}
			}
			currentBreakpoint = newBreakpoint;
		});
	}
	openedLayer = null;
	const searchElement = headerElement.querySelector(searchSelector);
	if (searchElement) {
		searchElement.addEventListener('transitionend', onSearchTransitionEnd);
	}
	headerElement.addEventListener('click', onClick);
	headerElement.addEventListener('focus', onFocus, true);
	headerElement.addEventListener('blur', onBlur, true);
	headerElement.addEventListener('mouseover', onMouseEnter);
	headerElement.addEventListener('mouseout', onMouseLeave);
	if (!hoistSecondaryNav() && document.readyState === 'loading') {
		document.addEventListener('DOMContentLoaded', hoistSecondaryNav);
	}

	return true;
};

if (!init()) {
	document.addEventListener('DOMContentLoaded', () => init());
}

// IE 11 support

try {
	if (/MSIE|Trident/.test(window.navigator.userAgent)) {
		document.getElementsByTagName('html')[0].classList.add('ie');
	}
} catch (e) {} // eslint-disable-line no-empty

// eslint-disable-next-line func-names
(function() {
	if (typeof window.CustomEvent === 'function') return;

	function CustomEvent(event, params) {
		// eslint-disable-next-line no-param-reassign
		params = params || { bubbles: false, cancelable: false, detail: null };
		const evt = document.createEvent('CustomEvent');
		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
		return evt;
	}

	window.CustomEvent = CustomEvent;
})();

const ElementPrototype = window.Element.prototype;
if (typeof ElementPrototype.matches !== 'function') {
	ElementPrototype.matches =
		ElementPrototype.msMatchesSelector ||
		ElementPrototype.mozMatchesSelector ||
		ElementPrototype.webkitMatchesSelector ||
		function matches(selector) {
			const element = this;
			const elements = (element.document || element.ownerDocument).querySelectorAll(selector);
			let index = 0;

			while (elements[index] && elements[index] !== element) {
				index += 1;
			}

			return Boolean(elements[index]);
		};
}
if (typeof ElementPrototype.closest !== 'function') {
	ElementPrototype.closest = function closest(selector) {
		let element = this;

		while (element && element.nodeType === 1) {
			if (element.matches(selector)) {
				return element;
			}
			element = element.parentNode;
		}

		return null;
	};
}
if (!String.prototype.endsWith) {
	// eslint-disable-next-line no-extend-native, func-names
	String.prototype.endsWith = function(search, len) {
		if (len === undefined || len > this.length) {
			// eslint-disable-next-line no-param-reassign
			len = this.length;
		}
		return this.substring(len - search.length, len) === search;
	};
}

if (!String.prototype.startsWith) {
	// eslint-disable-next-line no-extend-native
	Object.defineProperty(String.prototype, 'startsWith', {
		value(search, rawPos) {
			// eslint-disable-next-line no-bitwise
			const pos = rawPos > 0 ? rawPos | 0 : 0;
			return this.substring(pos, pos + search.length) === search;
		},
	});
}

if (window.NodeList && !NodeList.prototype.forEach) {
	// eslint-disable-next-line func-names
	NodeList.prototype.forEach = function(callback, thisArg) {
		// eslint-disable-next-line no-param-reassign
		thisArg = thisArg || window;
		for (let i = 0; i < this.length; i += 1) {
			callback.call(thisArg, this[i], i, this);
		}
	};
}
